import React from 'react'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import CTASection from '../../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import { Feature } from '../../components/Feature'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import { ReadNext } from '../../components/ReadNext'
import visibilityIcon from '../../assets/images/icons/visibility.svg'
import flowIcon from '../../assets/images/icons/flow.svg'
import Block from '../../components/Block'
import { flowIntro } from './flow'

export const visibilityIntro = (
  <>
    Teams need transparency to improve their ways of working. Gain an accurate
    view of where your team&#39;s time is spent, and boost focus on work that
    matters.
  </>
)

const VisibilityPage = () => {
  const visibilityImages = useStaticQuery(graphql`
    query {
      visibility1: file(relativePath: { eq: "images/visibility-1@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      visibility2: file(relativePath: { eq: "images/visibility-2@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      visibility3: file(relativePath: { eq: "images/visibility-3@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Visibility"
      description="Teams need transparency to improve their ways of working. Gain an accurate
      view of where your team's time is spent, and boost focus on work that
      matters."
    >
      <Hero>
        <div className="icon">
          <img src={visibilityIcon} aria-hidden="true" />
        </div>
        <h1>Visibility</h1>
        <div className="hero-description">
          <p className="large_text">{visibilityIntro}</p>
        </div>
        <MainDemoRequestCTA />
      </Hero>
      <div className="features" id="features">
        <Block>
          <Feature
            type="feature feature-grid-right"
            heading="Instant visibility into work in progress"
            image={visibilityImages.visibility1.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia Work Log with a Jira issue details shown"
          >
            <p>✅ Connect the dots between GitHub, Jira, and other systems</p>
            <p>✅ Follow daily progress on key projects</p>
            <p>
              ✅ Get more context by zooming into specific tasks, pull requests,
              and commits
            </p>
          </Feature>
          <Feature
            type="feature feature-grid-left"
            heading="Investment distribution"
            image={visibilityImages.visibility2.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia Investment distribution showing Jira epics, stories, tasks, bugs, and GitHub pull requests not linked to issues"
          >
            <p>
              ✅ Track focus on roadmap work, technical debt, bugs, and
              everything else
            </p>
            <p>
              ✅ Know how initiatives progress over weeks, months, and quarters
            </p>
            <p>✅ Eliminate guesswork by seeing exactly how time is spent</p>
            <p>
              ✅ Dive deeper with issue metrics like cycle time, scope creep,
              and flow efficiency
            </p>
          </Feature>
          <Feature
            type="feature feature-grid-right"
            heading="Industry-leading data quality"
            image={visibilityImages.visibility3.childImageSharp.gatsbyImageData}
            imageAlt="Screenshot of Swarmia's automated working agreement on linking GitHub pull requests to Jira issues"
          >
            <p>
              Swarmia&#39;s team-centric approach ensures that every
              contribution is assigned to the right team, person, and project
              across all systems.
            </p>
            <p>✅ Teams own their data</p>
            <p>✅ Automated and flexible mapping of work across systems</p>
            <p>✅ Advanced contributor management </p>
          </Feature>
        </Block>
      </div>
      <ReadNext
        label="Explore next"
        heading="Flow insights"
        icon={flowIcon}
        url="/product/flow/"
      >
        <p className="large_text">{flowIntro}</p>
      </ReadNext>
      <CTASection>
        Gain instant visibility into your ways&nbsp;of&nbsp;working.
        Join&nbsp;the&nbsp;1,000+ teams using Swarmia today.
      </CTASection>
    </Layout>
  )
}

export default VisibilityPage
